import {
    Home,
    Box,
    DollarSign,
    Tag,
    Clipboard,
    Camera,
    AlignLeft,
    UserPlus,
    Users,
    Chrome,
    BarChart,Settings,Archive, LogIn
} from 'react-feather';

export const MENUITEMS = [
    /*{
        path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },*/
    {
        title: 'Categorías', icon: Box, type: 'sub', active: false, children: [
            { path: '/category', title: 'Categorías', type: 'link' },
            { path: '/sub-category', title: 'Sub-Categorías', type: 'link' },
        ]
    },
    {
        title: 'Data', icon: Box, type: 'sub', active: false, children: [
            { path: '/monthly-sale', title: 'Venta Mensual', type: 'link' },
        ]
    },
    {
        title: 'Ventas por Sku', icon: Box, type: 'sub', active: false, children: [
            { path: '/sku-monthly-sale', title: 'Sku por meses', type: 'link' },
            { path: '/sku-monthly-sale-customer', title: 'Sku por Clientes', type: 'link' },
            { path: '/sku-monthly-sale-customer-list', title: 'Clientes', type: 'link' },
            { path: '/customers-list', title: 'Clientes por mes', type: 'link' },
        ]
    },
    {
        title: 'Productos', icon: Box, type: 'sub', active: false, children: [
            { path: '/product-list', title: 'Lista de Productos', type: 'link' },
            { path: '/add-product', title: 'Añadir Producto', type: 'link' },
            { path: '/product-stock', title: 'Stock', type: 'link' },
            { path: '/grammage', title: 'Gramaje', type: 'link' },
        ]
    },
    {
        title: 'Horarios', path: '/schedules', icon: Box, type: 'link', active: false
    },
    {
        title: 'Ventana de Entregas', path: '/pickup-windows', icon: Box, type: 'link', active: false
    },
    {
        title: 'Banners', path: '/banners', icon: Box, type: 'link', active: false
    },
    {
        title: 'Promociones', path: '/promotions', icon: Box, type: 'link', active: false
    },
    /*{
        title: 'Ventas', path: '/sales/orders', icon: DollarSign, type: 'link', active: false
    },*/
    /*{
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            {
                title: 'Physical', type: 'sub', active: false, children: [
                    { path: '/products/physical/category', title: 'Category', type: 'link' },
                    { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
                    { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
                    { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
                ]
            },
            {
                title: 'digital', type: 'sub', active: false, children: [
                    { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
                    { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
                    { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
                ]
            },
        ]
    },*/
    {
        title: 'Pedidos Recibidos', icon: DollarSign , type: 'sub', active: false, children: [
            { path: '/orders', title: 'Lista de Pedidos', type: 'link' },
            { path: '/orders-delivery', title: 'Pedidos para entrega', type: 'link' },
            // { path: '/product-stock', title: 'Stock', type: 'link' },
        ]
    },
    // {
    //     title: 'Pedidos Recibidos', path: '/orders', icon: DollarSign, type: 'link', active: false
    // },



    {
        title: 'Cobranzas', path: '/collections', icon: DollarSign, type: 'link', active: false
    },
    {
        title: 'Cargos', path: '/charges', icon: DollarSign, type: 'link', active: false
    },
    /*{
        title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/sales/orders', title: 'Orders', type: 'link' },
            { path: '/sales/transactions', title: 'Transactions', type: 'link' },
        ]
    },*/
    /*{
        title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
            { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
            { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
        ]
    },
    {
        title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
            { path: '/pages/list-page', title: 'List Page', type: 'link' },
            { path: '/pages/create-page', title: 'Create Page', type: 'link' },
        ]
    },
    {
        title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
    },*/
    /*{
        title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
            { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
            { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
        ]
    },*/
    {
        title: 'Usuarios', icon: UserPlus, type: 'sub', active: false, children: [
            { path: '/list-user', title: 'Lista de Usuarios', type: 'link' },
            { path: '/log-login', title: 'Intentos de Login', type: 'link' },
            { path: '/create-user', title: 'Añadir Usuario', type: 'link' },
            { path: '/list-pending-user', title: 'Aprobar Usuarios', type: 'link' },
            { path: '/import-user', title: 'Importar Usuarios', type: 'link' },
            { path: '/all-cash-user', title: 'Cash', type: 'link' },
        ]
    },
    {
        title: 'Solicitud de cambios', icon: UserPlus, type: 'sub', active: false, children: [
            { path: '/change-user-request', title: 'Datos de Usuarios', type: 'link' },
            { path: '/change-address-request', title: 'Dirección de Usuarios', type: 'link' },
        ]
    },
    /*{
        title: 'Vendors', icon: Users, type: 'sub', active: false, children: [
            { path: '/vendors/list_vendors', title: 'Vendor List', type: 'link' },
            { path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
        ]
    },
    {
        title: 'Localization', icon: Chrome, type: 'sub', children: [
            { path: '/localization/transactions', title: 'Translations', type: 'link' },
            { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
            { path: '/localization/taxes', title: 'Taxes', type: 'link' }
        ]
    },
    {
        title: 'Reports',path:'/reports/report', icon: BarChart, type: 'link', active: false
    },
    {
        title: 'Settings', icon: Settings, type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
        ]
    },
    {
        title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
    },*/
    {
        title: 'Configuración', path: '/settings', icon: Box, type: 'link', active: false
    },
    {
        title: 'Cerrar Sesión',path:'/auth/login', icon: LogIn, type: 'logout', active: false
    }
]

import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../common/dropzone'
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';

// image import
import primera_imagen from '../../assets/images/example/primera_imagen.png'
import segunda_imagen from '../../assets/images/example/segunda_imagen.png'
import logo_producto from '../../assets/images/example/logo_producto.png'
import primera_caracteristica from '../../assets/images/example/primera_caracteristica.png'
import segunda_caracteristica from '../../assets/images/example/segunda_caracteristica.png'
import tercera_caracteristica from '../../assets/images/example/tercera_caracteristica.png'

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

const items = [];
export class Ver_edit_pro extends Component {
    selectRef = null;
    constructor(props) {
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeSummary = this.onChangeSummary.bind(this);
        this.onChangePerformance = this.onChangePerformance.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeStock = this.onChangeStock.bind(this);
        this.onChangeCost = this.onChangeCost.bind(this);
        this.onChangeSmell = this.onChangeSmell.bind(this);
        this.onChangePuesta = this.onChangePuesta.bind(this);
        this.onChangeInfo = this.onChangeInfo.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePriceDiscount = this.onChangePriceDiscount.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeInternalCode = this.onChangeInternalCode.bind(this);
        
        this.state = {
            open_example: false,
            img_example: "",
            description: "",
            dataCateg: [],
            dataListCateg: [],
            dataSubCateg: [],
            selectedOption: null,
            selectedSubCategOption: null,
            dataGramaje1: [],
            selectedOptionGramaje1: null,
            dataGramaje2: [],
            selectedOptionGramaje2: null,
            dataGramaje3: [],
            selectedOptionGramaje3: null,
            name: "",
            summary: "",
            performance: "",
            content: "",
            price: "",
            price_discount: "",
            stock: "",
            cost: "",
            status: "ACTIVATED",
            background_url: "www.verudar.com",
            loading: false,
            message: "",
            validateSelect: "",
            file: null,
            file_backup:null,
            flag_validate_image: false,
            file_image_1: null,
            file_backup_image_1:null,
            file_image_2: null,
            file_backup_image_2:null,
            file_image_3: null,
            file_backup_image_3:null,
            file_image_4: null,
            file_backup_image_4:null,
            file_logo: null,
            file_backup_logo:null,
            errors: {},
            selectedTemplateOption: null,
            data_template: [
              {value: 'TEMPLATE1',label: 'Plantilla 1'},
              {value: 'TEMPLATE2',label: 'Plantilla 2'}
            ],
            template: "",
            sub_categ_id: 0,
            sub_categ_flag: 0,
            flag_validate_image_2: false,
            flag_validate_logo: false,
            internal_code: ""
        }

    }

    componentWillMount() {
        this.getDataCateg();
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
      }

      onChangeSummary(e) {
        this.setState({
            summary: e.target.value
        });
      }

      onChangePerformance(e) {
        this.setState({
            performance: e.target.value
        });
      }

      onChangeSmell(e) {
        this.setState({
            smell: e.target.value
        });
      }

      onChangePuesta(e) {
        this.setState({
            puesta: e.target.value
        });
      }

      onChangeInfo(e) {
        this.setState({
            info: e.target.value
        });
      }

      onChangeContent(e) {
        this.setState({
            content: e.target.value
        });
      }      

      onChangePrice(e) {
        this.setState({
            price: e.target.value
        });
      }

      onChangeInternalCode(e) {
        this.setState({
            internal_code: e.target.value
        })
      }

      onChangeStock(e) {
        this.setState({
            stock: e.target.value
        });
      }

      onChangeCost(e) {
        this.setState({
            cost: e.target.value
        });
      }

      onChangePriceDiscount(e) {
        this.setState({
            price_discount: e.target.value
        });
      }

      
      onChangeStatus(e) {
        this.setState({
            status: e.target.value
        });
      }

      onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
      }

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        ApiService.getCategoriesList(user.token).then(
            (res) => {
                if(res && res.status == 'success')
                {
                    let items_parent = [];
                    //console.log(res.data);
                    this.setState({ dataListCateg: res.data });
                    for (let i = 0; i < res.data.length; i++) {
                        let array_data = {};
                        array_data['value'] = res.data[i]['id'];
                        array_data['label'] = res.data[i]['name'] + ' - ' + res.data[i]['template'];
                        items_parent.push(array_data);
                    }
                    this.setState({ dataCateg: items_parent });
                    this.getDataGrammage();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getDataGrammage(){
        var user = AuthService.getCurrentUser();
        ApiService.getDataGrammage(user.token,0).then(
            (res) => {
                if(res && res.status == 'success'){
                    let items_1 = [];
                    let items_2 = [];
                    let items_3 = [];
                    console.log(res.data);
                    for (let i = 0; i < res.data.length; i++) {
                        if(res.data[i]['type'] == 'TIPO1'){
                            let array_data = {};
                            array_data['value'] = res.data[i]['id'];
                            array_data['label'] = res.data[i]['name'];
                            items_1.push(array_data);
                        }else if(res.data[i]['type'] == 'TIPO2'){
                            let array_data = {};
                            array_data['value'] = res.data[i]['id'];
                            array_data['label'] = res.data[i]['name'];
                            items_2.push(array_data);
                        }else{
                            let array_data = {};
                            array_data['value'] = res.data[i]['id'];
                            array_data['label'] = res.data[i]['name'];
                            items_3.push(array_data);
                        }
                    }
                    this.setState({ dataGramaje1: items_1 });
                    this.setState({ dataGramaje2: items_2 });
                    this.setState({ dataGramaje3: items_3 });
                    /*this.items = res.data.map(function(row) {
                        return { value: row.id, label : row.name, key: row.id }
                     })
                    this.setState({ dataCateg: this.items });*/
                    this.getDataProduct();
                }else{
                    this.getDataProduct();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getDataProduct(){
        var user = AuthService.getCurrentUser();
        let id_product = this.props.match.params.id;
        ApiService.getProductDetails(user.token,id_product).then(
            (res) => {
                //console.log(res)
                if(res && res.status === 'success')
                {
                    this.setState({ name: res.data.name });
                    this.setState({ summary: res.data.summary });
                    this.setState({ performance: res.data.performance });
                    this.setState({ content: res.data.content });
                    this.setState({ price: res.data.price });
                    this.setState({ price_discount: res.data.price_discount });
                    //this.setState({ stock: res.data.Stock.variable_stock });
                    //this.setState({ cost: res.data.cost });
                    this.setState({ price_discount: res.data.price_discount });
                    this.setState({ status: res.data.status });
                    this.setState({ smell: res.data.smell });
                    this.setState({ puesta: res.data.puesta });
                    this.setState({ info: res.data.info });
                    //this.setState({ selectedOption: res.data.id_category });
                    this.setState({ description: res.data.description });
                    this.setState({ internal_code: res.data.internal_code });
                    //console.log(this.state.dataCateg);
                    let array_categ_parent = [];
                    array_categ_parent = this.state.dataListCateg;
                    let flag_floop = 0;
                    for (let i = 0; i < array_categ_parent.length; i++){
                        console.log('array_categ_parent'+i,array_categ_parent[i]);
                        let array_sub_categ = [];
                        array_sub_categ = array_categ_parent[i]['SubCategories'];
                        for (let j = 0; j < array_sub_categ.length; j++) {
                            const element = array_sub_categ[j];
                            if(element.id == res.data.id_category)
                            {
                                //console.log('element',element);
                                flag_floop = 1;
                                console.log('this.state.dataCateg[i]',this.state.dataCateg[i]);
                                this.setState({ sub_categ_id: element.id });
                                //sub_categ_id = element.id;
                                console.log('sub_categ_id',this.state.sub_categ_id);
                                this.handleChange(this.state.dataCateg[i]);
                                break;
                            }
                        }
                        if(flag_floop == 1)
                        {
                            break;
                        }
                       
                    }
                   
                    this.setState({ file: {name : res.data.url} });
                    this.setState({ file_backup: res.data.url });
                    if(!res.data.url){
                        this.setState({flag_validate_image: true});
                    }
                    this.setState({ file_image_1: {name : res.data.image_1} });
                    this.setState({ file_backup_image_1: res.data.image_1 });
                    this.setState({ file_image_2: {name : res.data.image_2} });
                    this.setState({ file_backup_image_2: res.data.image_2 });
                    this.setState({ file_image_3: {name : res.data.image_3} });
                    this.setState({ file_backup_image_3: res.data.image_3 });
                    this.setState({ file_image_4: {name : res.data.image_4} });
                    this.setState({ file_backup_image_4: res.data.image_4 });
                    if(!res.data.image_4){
                        this.setState({flag_validate_image_2: true});
                    }
                    this.setState({ file_logo: {name : res.data.logo} });
                    this.setState({ file_backup_logo: res.data.logo });
                    if(!res.data.logo){
                        this.setState({flag_validate_logo: true});
                    }

                    let array_grammage = res.data.Grammage_Product;
                    if(array_grammage.length > 0)
                    {
                        for (let index = 0; index < array_grammage.length; index++) {
                            const element = array_grammage[index];
                            console.log('element',element.Grammage.type);
                            if(element.Grammage.type == 'TIPO1')
                            {
                                for (let i = 0; i < this.state.dataGramaje1.length; i++){
                                    if(this.state.dataGramaje1[i]['value'] === element.Grammage.id){
                                        console.log(this.state.dataGramaje1[i]['value'])
                                        this.handleChangeGramaje1(this.state.dataGramaje1[i])
                                    }
                                }
                            }else if(element.Grammage.type == 'TIPO2')
                            {
                                for (let i = 0; i < this.state.dataGramaje2.length; i++){
                                    if(this.state.dataGramaje2[i]['value'] === element.Grammage.id){
                                        console.log(this.state.dataGramaje2[i]['value'])
                                        this.handleChangeGramaje2(this.state.dataGramaje2[i])
                                    }
                                }
                            }else{
                                for (let i = 0; i < this.state.dataGramaje3.length; i++){
                                    if(this.state.dataGramaje3[i]['value'] === element.Grammage.id){
                                        console.log(this.state.dataGramaje3[i]['value'])
                                        this.handleChangeGramaje3(this.state.dataGramaje3[i])
                                    }
                                }
                            }
                        }
                        
                    }
                    
                }

            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderSelect(){
        return this.state.dataCateg.length === 0 
			? ''
			: this.state.dataCateg.map((item) => (
                <option className="dd-list-item" value={item.id} key={item.id} >{item.name}</option>
            ))    
    }

    handleChange = selectedOption => {
        console.log('this.state.dataListCateg',this.state.dataListCateg);
        let array_categ = [];
        array_categ = this.state.dataListCateg;
        console.log('array_categ',array_categ);
        console.log('selectedOption',selectedOption);
        this.selectRef.select.clearValue();
        for (let i = 0; i < array_categ.length; i++) {
            if(selectedOption.value === array_categ[i]['id'])
            {
                this.setState({ template: array_categ[i]['template'] });
                console.log('SubCategories',array_categ[i]['SubCategories']);
                let items_sub_categ = [];
                let array_sub_categ = [];
                array_sub_categ = array_categ[i]['SubCategories'];
                let index = null;
                for (let j = 0; j < array_sub_categ.length; j++) {
                    let array_data = {};
                    array_data['value'] = array_sub_categ[j]['id'];
                    array_data['label'] = array_sub_categ[j]['name'];
                    items_sub_categ.push(array_data);
                    //Si coincide el valor de la sub categ y el flag indica que recien cargo la pagina
                    if(this.state.sub_categ_id == array_sub_categ[j]['id'] && this.state.sub_categ_flag == 0)
                    {
                        index = j;
                        //sub_categ_flag = 1;
                        this.setState({ sub_categ_flag: 1 });
                    }
                }
                this.setState({ dataSubCateg: items_sub_categ });
                if(index != null)
                {
                    this.handleChangeSubCateg(this.state.dataSubCateg[index]);
                }
                break;
            }
        }
        this.setState(
          { selectedOption },
          () => console.log(`Option selected:`, this.state.selectedOption)
        );
      };

      handleChangeSubCateg = selectedSubCategOption => {
        //console.log(selectedOptionGramaje1)
        this.setState(
          { selectedSubCategOption },
          () => console.log(`Option selected:`, this.state.selectedSubCategOption)
        );
      };

      handleChangeGramaje1 = selectedOptionGramaje1 => {
        //console.log(selectedOptionGramaje1)
        this.setState(
          { selectedOptionGramaje1 },
          () => console.log(`Option selected:`, this.state.selectedOptionGramaje1)
        );
      };

      handleChangeGramaje2 = selectedOptionGramaje2 => {
        //console.log(selectedOptionGramaje2)
        this.setState(
          { selectedOptionGramaje2 },
          () => console.log(`Option selected:`, this.state.selectedOptionGramaje2)
        );
      };

      handleChangeGramaje3 = selectedOptionGramaje3 => {
        //console.log(selectedOptionGramaje3)
        this.setState(
          { selectedOptionGramaje3 },
          () => console.log(`Option selected:`, this.state.selectedOptionGramaje3)
        );
      };

      handleTemplateChange = selectedTemplateOption => {
        this.setState(
          { selectedTemplateOption },
          () => console.log(`Option selected:`, this.state.selectedTemplateOption)
        );
      };


      handleUpdate(e) {
        e.preventDefault();
    
        this.form.validateAll();

        if(!this.state.selectedOption){
            this.setState({
                errors: {category : "Error"}
            }) 
            return true;
        }else{
            this.setState({
                errors: {category : ""}
            }) 
        }

        if(!this.state.selectedSubCategOption){
            this.setState({
                errors: {subcategory : "Error"}
            }) 
            return true;
          }else{
              this.setState({
                  errors: {subcategory : ""}
              }) 
          }

        /*if(!this.state.selectedTemplateOption){
            this.setState({
                errors: {template : "Error"}
            }) 
          }else{
              this.setState({
                  errors: {template : ""}
              }) 
          }*/
    
        if (this.checkBtn.context._errors.length === 0) {
            this.setState({
                message: "",
                loading: true
              });
            var user = AuthService.getCurrentUser();
            let id_product = this.props.match.params.id;
            let formdata = new FormData()
            formdata.append('id_product', id_product)
            formdata.append('name', this.state.name)
            if(this.state.file_backup != this.state.file.name)
            {
                formdata.append('url', this.state.file)
            }
            if(this.state.file_backup_image_1 != this.state.file_image_1.name)
            {
                formdata.append('image_1', this.state.file_image_1)
            }
            if(this.state.file_backup_image_2 != this.state.file_image_2.name)
            {
                formdata.append('image_2', this.state.file_image_2)
            }
            if(this.state.file_backup_image_3 != this.state.file_image_3.name)
            {
                formdata.append('image_3', this.state.file_image_3)
            }
            if(this.state.file_backup_image_4 != this.state.file_image_4.name)
            {
                formdata.append('image_4', this.state.file_image_4)
            }
            if(this.state.file_backup_logo != this.state.file_logo.name)
            {
                formdata.append('logo', this.state.file_logo)
            }
            formdata.append('description', this.state.description)
            if(this.state.price)
            {
                formdata.append('price', this.state.price)
            }
            if(this.state.price_discount)
            {
                formdata.append('price_discount', this.state.price_discount)
            }
            formdata.append('status', this.state.status)
            //formdata.append('performance', this.state.performance)
            formdata.append('content', this.state.content)
            //formdata.append('summary', this.state.summary)
            formdata.append('id_category', this.state.selectedSubCategOption.value)
            formdata.append('smell', this.state.smell)
            formdata.append('puesta', this.state.puesta)
            formdata.append('info', this.state.info)
            formdata.append('grammage1', this.state.selectedOptionGramaje1?this.state.selectedOptionGramaje1.value:0)
            formdata.append('grammage2', this.state.selectedOptionGramaje2?this.state.selectedOptionGramaje2.value:0)
            formdata.append('grammage3', this.state.selectedOptionGramaje3?this.state.selectedOptionGramaje3.value:0)
            formdata.append('internal_code', this.state.internal_code)

            //formdata.append('cost', this.state.cost)
            //formdata.append('stock', this.state.stock)
            //formdata.append('template', this.state.selectedTemplateOption.value)
            /*for (var pair of formdata.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/

            ApiService.updateProduct(user.token,formdata).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        toast.success("Producto actualizado!");
                        this.props.history.push("/product-list");
                        /*window.location.reload();*/
                    }else{
                        toast.error(res.message);
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

    onShowExample = (img) => {
        this.setState({ open_example: true });
        console.log(img)
        this.setState({
          img_example: img
        }, function () {
            console.log('');
        });
    };
    
    onCloseExample = () => {
      this.setState({ open_example: false });
    };


    render() {
        const { selectedOption, selectedSubCategOption, selectedOptionGramaje1, selectedOptionGramaje2, selectedOptionGramaje3 , open_example } = this.state;
        let url_image = `${process.env.REACT_APP_URL_FILES}/images/products/`;
        const { selectedTemplateOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Editar Producto" parent="Digital" />
                <div className="container-fluid">
                    <Form
                        onSubmit={this.handleUpdate} 
                        encType="multipart/form-data" 
                        method="POST"
                        ref={c => {this.form = c;
                    }}>
                    <div className="row product-adding">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Nombre</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.name} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeName}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Código interno</label>
                                            <Input 
                                                name="content" 
                                                value={this.state.internal_code} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeInternalCode}
                                                validations={[required]}
                                            />
                                            {/*<input className="form-control" id="validationCustom02" type="text" required="" />*/}
                                        </div>

                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Estado</label>
                                            <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block">
                                                <Input 
                                                    id="edo-ani"
                                                    name="status" 
                                                    value="ACTIVATED"
                                                    type="radio" 
                                                    className="radio_animated" 
                                                    checked={this.state.status === "ACTIVATED"}
                                                    onChange={this.onChangeStatus}
                                                    validations={[required]}
                                                />
                                                Activo
                                                    {/*<input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />*/}
                                            </label>
                                                <label className="d-block" >
                                                <Input 
                                                    id="edo-ani"
                                                    name="status" 
                                                    value="DESACTIVATED"
                                                    type="radio" 
                                                    className="radio_animated" 
                                                    checked={this.state.status === "DESACTIVATED"}
                                                    onChange={this.onChangeStatus}
                                                    validations={[required]}
                                                />
                                                Inactivo
                                                    {/*<input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />*/}
                                            </label>
                                            </div>
                                        </div>
                                        {/*<div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> SKU</label>
                                            <input className="form-control" id="validationCustom02" type="text" required="" />
                                        </div>*/}
                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Categoría</label>
                                            {/*<select className="custom-select" required="">
                                                <option value="">--Selecciona uno--</option>
                                                {this.renderSelect()}
                                            </select>*/}
                                            <Select
                                                value={selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.dataCateg}
                                            />
                                            {
                                            this.state.errors.category && !this.state.selectedOption?
                                            <div className="alert alert-danger" role="alert" >Este campo es obligatorio!</div>
                                            :''}
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Sub-Categoría</label>
                                            {/*<select className="custom-select" required="">
                                                <option value="">--Selecciona uno--</option>
                                                {this.renderSelect()}
                                            </select>*/}
                                            <Select
                                                ref={ref => {
                                                    this.selectRef = ref;
                                                }}
                                                value={selectedSubCategOption}
                                                onChange={this.handleChangeSubCateg}
                                                options={this.state.dataSubCateg}
                                            />
                                            {
                                            this.state.errors.subcategory && !this.state.selectedSubCategOption?
                                            <div className="alert alert-danger" role="alert" >Este campo es obligatorio!</div>
                                            :''}
                                        </div>
                                       {/*<div className="form-group">
                                            <label htmlFor="col-form-label"><span>*</span> Plantilla</label>
                                            <Select
                                                value={selectedTemplateOption}
                                                onChange={this.handleTemplateChange}
                                                options={this.state.data_template}
                                            />
                                            {
                                            this.state.errors.template && !this.state.selectedTemplateOption?
                                            <div className="alert alert-danger" role="alert" >Este campo es obligatorio!</div>
                                            :''}
                                        </div>*/}
                                        {this.state.template == 'TEMPLATE2' || this.state.template == 'TEMPLATE3'?

                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Descripción</label>
                                            <TextArea 
                                                name="description" 
                                                value={this.state.description} 
                                                type="text" 
                                                rows="4"
                                                cols="12"
                                                onChange={this.onChangeDescription}
                                                //validations={[required]}
                                            />
                                        </div>
                                        :''
                                        }
                                        {/*<div className="form-group">
                                            <label className="col-form-label">Resumen</label>
                                            <TextArea 
                                                name="summary" 
                                                value={this.state.summary} 
                                                type="text" 
                                                rows="4"
                                                cols="12"
                                                onChange={this.onChangeSummary}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Performance</label>
                                            <Input 
                                                name="performance" 
                                                value={this.state.performance} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangePerformance}
                                            />
                                        </div>*/}
                                        {this.state.template == 'TEMPLATE2'?

                                        <div className="form-group">
                                            <label className="col-form-label">Contenido</label>
                                            <Input 
                                                name="content" 
                                                value={this.state.content} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeContent}
                                                //validations={[required]}
                                            />
                                            {/*<input className="form-control" type="text" required="" />*/}
                                        </div>
                                        :''    
                                        }
                                        {this.state.template == 'TEMPLATE2' || this.state.template == 'TEMPLATE3'?
                                        <>
                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Precio</label>
                                            <Input 
                                                name="content" 
                                                value={this.state.price} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangePrice}
                                                //validations={[required]}
                                            />
                                            {/*<input className="form-control" id="validationCustom02" type="text" required="" />*/}
                                        </div>

                                        
                                        {/*<div className="form-group">
                                            <label className="col-form-label"><span>*</span> Precio descuento</label>
                                            <Input 
                                                name="content" 
                                                value={this.state.price_discount} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangePriceDiscount}
                                            />
                                        </div>*/}
                                        </>
                                        :''    
                                        }
                                        {/*<div className="form-group">
                                            <label className="col-form-label"><span>*</span> Stock</label>
                                            <Input 
                                                name="content" 
                                                value={this.state.stock} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeStock}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Costo</label>
                                            <Input 
                                                name="content" 
                                                value={this.state.cost} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeCost}
                                                validations={[required]}
                                            />
                                        </div>*/}
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5></h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation prod-block-right">
                                    {this.state.template == 'TEMPLATE1'?
                                        <>
                                        <div className="form-group">
                                            <label className="col-form-label">Aroma</label>
                                            <Input 
                                                name="smell" 
                                                value={this.state.smell} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeSmell}
                                                maxLength="200"
                                                //validations={[required]}
                                            />
                                            {/*<input className="form-control" type="text" required="" />*/}
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Puesta</label>
                                            <Input 
                                                name="puesta" 
                                                value={this.state.puesta} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangePuesta}
                                                maxLength="200"
                                                //validations={[required]}
                                            />
                                            {/*<input className="form-control" type="text" required="" />*/}
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Dato</label>
                                            <Input 
                                                name="info" 
                                                value={this.state.info} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeInfo}
                                                maxLength="200"
                                                //validations={[required]}
                                            />
                                            {/*<input className="form-control" type="text" required="" />*/}
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label"> Gramaje 1 (THC) (300 x 300) <span onClick={(event) => this.onShowExample('primera_caracteristica')}><b>(?)</b></span></label>
                                            {/*<select className="custom-select" required="">
                                                <option value="">--Selecciona uno--</option>
                                                {this.renderSelect()}
                                            </select>*/}
                                            <Select
                                                value={selectedOptionGramaje1}
                                                onChange={this.handleChangeGramaje1}
                                                options={this.state.dataGramaje1}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label"> Gramaje 2 (SEEDS) (300 x 300) <span onClick={(event) => this.onShowExample('segunda_caracteristica')}><b>(?)</b></span></label>
                                            {/*<select className="custom-select" required="">
                                                <option value="">--Selecciona uno--</option>
                                                {this.renderSelect()}
                                            </select>*/}
                                            <Select
                                                value={selectedOptionGramaje2}
                                                onChange={this.handleChangeGramaje2}
                                                options={this.state.dataGramaje2}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label"> Gramaje 3 (SATIVA) (300 x 300) <span onClick={(event) => this.onShowExample('tercera_caracteristica')}><b>(?)</b></span></label>
                                            {/*<select className="custom-select" required="">
                                                <option value="">--Selecciona uno--</option>
                                                {this.renderSelect()}
                                            </select>*/}
                                            <Select
                                                value={selectedOptionGramaje3}
                                                onChange={this.handleChangeGramaje3}
                                                options={this.state.dataGramaje3}
                                            />
                                        </div>
                                        </>
                                        :''    
                                        }
                                        {this.state.template == 'TEMPLATE2' || this.state.template == 'TEMPLATE3'?
                                        <>
                                        <div className="form-group">
                                        <label className="col-form-label pt-0">Primera Imagen del Producto (300 x 450) <span onClick={(event) => this.onShowExample('primera_imagen')}><b>(?)</b></span></label>
                                        <Input 
                                            id="url"
                                            name="url" 
                                            //value={this.state.file} 
                                            type="file" 
                                            className="form-control" 
                                            accept="image/*"
                                            //validations={[required]}
                                            //validations={this.state.flag_validate_image?[required]:[]}
                                            onChange={(e) => {
                                                this.setState({file: e.target.files[0]}, () => {
                                                //console.log('state', this.state.file);
                                                })
                                            }}
                                        />
                                        {
                                            this.state.file_backup?
                                            <img src={url_image + this.state.file_backup} style={{width:100,height:100}}/>
                                            :
                                            ''
                                        }
                                        </div>
                                        </>
                                        :
                                        ''
                                        }
                                        {this.state.template == 'TEMPLATE1'?
                                        <>
                                        <div className="form-group">
                                        <label className="col-form-label pt-0">Segunda Imagen del Producto (300 x 450) <span onClick={(event) => this.onShowExample('segunda_imagen')}><b>(?)</b></span></label>
                                        <Input 
                                            id="url"
                                            name="url" 
                                            //value={this.state.file} 
                                            type="file" 
                                            className="form-control" 
                                            accept="image/*"
                                            //validations={this.state.flag_validate_image_2?[required]:[]}
                                            onChange={(e) => {
                                                this.setState({file_image_4: e.target.files[0]}, () => {
                                                //console.log('state', this.state.file);
                                                })
                                            }}
                                        />
                                        {
                                            this.state.file_backup_image_4?
                                            <img src={url_image + this.state.file_backup_image_4} style={{width:100,height:100}}/>
                                            :
                                            ''
                                        }
                                        </div>
                                        <div className="form-group">
                                        <label className="col-form-label pt-0">Logo (400 x 400) <span onClick={(event) => this.onShowExample('logo_producto')}><b>(?)</b></span></label>
                                        <Input 
                                            id="url"
                                            name="url" 
                                            //value={this.state.file} 
                                            type="file" 
                                            className="form-control" 
                                            accept="image/*"
                                            //validations={this.state.flag_validate_logo?[required]:[]}
                                            onChange={(e) => {
                                                this.setState({file_logo: e.target.files[0]}, () => {
                                                //console.log('state', this.state.file);
                                                })
                                            }}
                                        />
                                        {
                                            this.state.file_backup_logo?
                                            <img src={url_image + this.state.file_backup_logo} style={{width:100,height:100}}/>
                                            :
                                            ''
                                        }
                                        </div>
                                        </>
                                        :''
                                        }
                                        {/*<div className="form-group">
                                        <label className="col-form-label pt-0">Imagen de Característica 1 <span onClick={(event) => this.onShowExample('primera_caracteristica')}><b>(?)</b></span></label>
                                        <Input 
                                            id="url"
                                            name="url" 
                                            type="file" 
                                            className="form-control" 
                                            accept="image/*"
                                            onChange={(e) => {
                                                this.setState({file_image_1: e.target.files[0]}, () => {
                                                })
                                            }}
                                        />
                                        {
                                            this.state.file_backup_image_1?
                                            <img src={url_image + this.state.file_backup_image_1} style={{width:100,height:100}}/>
                                            :
                                            ''
                                        }
                                        </div>*/}
                                        {/*<div className="form-group">
                                        <label className="col-form-label pt-0">Imagen de Característica 2 <span onClick={(event) => this.onShowExample('segunda_caracteristica')}><b>(?)</b></span></label>
                                        <Input 
                                            id="url"
                                            name="url" 
                                            type="file" 
                                            className="form-control" 
                                            accept="image/*"
                                            onChange={(e) => {
                                                this.setState({file_image_2: e.target.files[0]}, () => {
                                                })
                                            }}
                                        />
                                        {
                                            this.state.file_backup_image_2?
                                            <img src={url_image + this.state.file_backup_image_2} style={{width:100,height:100}}/>
                                            :
                                            ''
                                        }
                                        </div>*/}
                                        {/*<div className="form-group">
                                        <label className="col-form-label pt-0">Imagen de Característica 3 <span onClick={(event) => this.onShowExample('tercera_caracteristica')}><b>(?)</b></span></label>
                                        <Input 
                                            id="url"
                                            name="url" 
                                            type="file" 
                                            className="form-control" 
                                            accept="image/*"
                                            onChange={(e) => {
                                                this.setState({file_image_3: e.target.files[0]}, () => {
                                                })
                                            }}
                                        />
                                        {
                                            this.state.file_backup_image_3?
                                            <img src={url_image + this.state.file_backup_image_3} style={{width:100,height:100}}/>
                                            :
                                            ''
                                        }
                                        </div>*/}
                                        
                                    </div>
                                    
                                    <button 
                                        className="btn btn-primary"  
                                        disabled={this.state.loading}
                                    >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Guardar</span>
                                    </button> 
                                </div>
                                {/*<div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group mb-0">
                                            <div className="description-sm">
                                                <CKEditors
                                                    activeclassName="p10"
                                                    description={this.state.description}
                                                    onChange={this.onChangeDescription}
                                                    value={this.state.description} 
                                                    events={{
                                                        "blur": this.onBlur,
                                                        "afterPaste": this.afterPaste,
                                                        "change": this.onChange
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                            {/*<div className="card">
                                <div className="card-header">
                                    <h5>Meta Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"> Meta Title</label>
                                            <input className="form-control" id="validationCustom05" type="text" required="" />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Meta Description</label>
                                            <textarea rows="4" cols="12"></textarea>
                                        </div>
                                        <div className="form-group mb-0">
                                            <div className="product-buttons text-center">
                                                <button type="button" className="btn btn-primary">Add</button>
                                                <button type="button" className="btn btn-light">Discard</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                    />
                    </Form>
                    <Modal open={open_example} onClose={this.onCloseExample} >
                        <div className="modal-header">
                            <h5 className="modal-title f-w-600" id="exampleModalLabel2">Ejemplo</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div style={{ margin: '0 auto' }}>
                                    <div className="form-group">
                                    {
                                        this.state.img_example == 'primera_imagen'?
                                        <img className="blur-up lazyloaded" src={primera_imagen} alt="" />
                                        :this.state.img_example == 'segunda_imagen'?
                                        <img className="blur-up lazyloaded" src={segunda_imagen} alt="" />
                                        :this.state.img_example == 'logo_producto'?
                                        <img className="blur-up lazyloaded" src={logo_producto} alt="" />
                                        :this.state.img_example == 'primera_caracteristica'?
                                        <img className="blur-up lazyloaded" src={primera_caracteristica} alt="" />
                                        :this.state.img_example == 'segunda_caracteristica'?
                                        <img className="blur-up lazyloaded" src={segunda_caracteristica} alt="" />
                                        :this.state.img_example == 'tercera_caracteristica'?
                                        <img className="blur-up lazyloaded" src={tercera_caracteristica} alt="" />
                                        :''
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ textAlign: 'center', display: 'block', fontWeight: 'bolder', fontSize: '20px' }}>
                            {
                                this.state.img_example == 'primera_imagen'?
                                <>Tamaño 300 x 450</>
                                :this.state.img_example == 'segunda_imagen'?
                                <>Tamaño 300 x 450</>
                                :this.state.img_example == 'logo_producto'?
                                <>Tamaño 400 x 400</>
                                :this.state.img_example == 'primera_caracteristica'?
                                <>Tamaño 300 x 300</>
                                :this.state.img_example == 'segunda_caracteristica'?
                                <>Tamaño 300 x 300</>
                                :this.state.img_example == 'tercera_caracteristica'?
                                <>Tamaño 300 x 300</>
                                :''
                            } 
                        </div>
                    </Modal>
                </div>
            </Fragment>
        )
    }
}

export default Ver_edit_pro
